<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title">Customize Trending</p>
      </div>
    </div>
    <hr />
    <div class="columns">
      <div class="column">
        <form @submit.prevent="submitForm" id="editorForm">
          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title1" required></b-input>
                </b-field>
                <b-field label="Resource Type*">
                  <b-select
                    required
                    v-model="formData.resourceType1"
                    placeholder="Select a type"
                  >
                    <option value="PrintedBook">Book</option>
                    <option value="eBook">E-Book</option>
                    <option value="Research">Research</option>
                    <option value="eResearch">E-Research Article</option>
                    <option value="Journal">Journal</option>
                    <option value="eJournal">E-Journal</option>
                    <option value="eNewspaper">E-Newspaper</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="map">Map</option>
                    <option value="dataset">Dataset</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title2" required></b-input>
                </b-field>
                <b-field label="Resource Type*">
                  <b-select
                    required
                    v-model="formData.resourceType2"
                    placeholder="Select a type"
                  >
                    <option value="PrintedBook">Book</option>
                    <option value="eBook">E-Book</option>
                    <option value="Research">Research</option>
                    <option value="eResearch">E-Research Article</option>
                    <option value="Journal">Journal</option>
                    <option value="eJournal">E-Journal</option>
                    <option value="eNewspaper">E-Newspaper</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="map">Map</option>
                    <option value="dataset">Dataset</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title3" required></b-input>
                </b-field>
                <b-field label="Resource Type*">
                  <b-select
                    required
                    v-model="formData.resourceType3"
                    placeholder="Select a type"
                  >
                    <option value="PrintedBook">Book</option>
                    <option value="eBook">E-Book</option>
                    <option value="Research">Research</option>
                    <option value="eResearch">E-Research Article</option>
                    <option value="Journal">Journal</option>
                    <option value="eJournal">E-Journal</option>
                    <option value="eNewspaper">E-Newspaper</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="map">Map</option>
                    <option value="dataset">Dataset</option>
                  </b-select></b-field
                >
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title4" required></b-input>
                </b-field>
                <b-field label="Resource Type*">
                  <b-select
                    required
                    v-model="formData.resourceType4"
                    placeholder="Select a type"
                  >
                    <option value="PrintedBook">Book</option>
                    <option value="eBook">E-Book</option>
                    <option value="Research">Research</option>
                    <option value="eResearch">E-Research Article</option>
                    <option value="Journal">Journal</option>
                    <option value="eJournal">E-Journal</option>
                    <option value="eNewspaper">E-Newspaper</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="map">Map</option>
                    <option value="dataset">Dataset</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title5" required></b-input>
                </b-field>
                <b-field label="Resource Type*">
                  <b-select
                    required
                    v-model="formData.resourceType5"
                    placeholder="Select a type"
                  >
                    <option selected value="PrintedBook">Book</option>
                    <option value="eBook">E-Book</option>
                    <option value="Research">Research</option>
                    <option value="eResearch">E-Research Article</option>
                    <option value="Journal">Journal</option>
                    <option value="eJournal">E-Journal</option>
                    <option value="eNewspaper">E-Newspaper</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="map">Map</option>
                    <option value="dataset">Dataset</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title6" required></b-input>
                </b-field>
                <b-field label="Resource Type*">
                  <b-select
                    required
                    v-model="formData.resourceType6"
                    placeholder="Select a type"
                  >
                    <option selected value="PrintedBook">Book</option>
                    <option value="eBook">E-Book</option>
                    <option value="Research">Research</option>
                    <option value="eResearch">E-Research Article</option>
                    <option value="Journal">Journal</option>
                    <option value="eJournal">E-Journal</option>
                    <option value="eNewspaper">E-Newspaper</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="map">Map</option>
                    <option value="dataset">Dataset</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <hr />
          <div class="has-text-right">
            <!-- <b-button @click="cancel" class="button">Cancel</b-button> -->
            <button
              form="editorForm"
              class="button is-primary ml-4"
              type="submit"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? "Saving" : "Save" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      trendingItems: undefined,

      formData: {
        title1: undefined,
        resourceType1: undefined,
        title2: undefined,
        resourceType2: undefined,
        title3: undefined,
        resourceType3: undefined,
        title4: undefined,
        resourceType4: undefined,
        title5: undefined,
        resourceType5: undefined,
        title6: undefined,
        resourceType6: undefined
      }
    };
  },
  async created() {
    await this.$store.dispatch("getTrendingObjects");
    this.trendingItems = this.items;
    this.trendingItems.forEach(item => {
      if (item.trendingNo == 1) {
        this.formData.title1 = item.title;
        this.formData.resourceType1 = item.resourceType;
      } else if (item.trendingNo == 2) {
        this.formData.title2 = item.title;
        this.formData.resourceType2 = item.resourceType;
      } else if (item.trendingNo == 3) {
        this.formData.title3 = item.title;
        this.formData.resourceType3 = item.resourceType;
      } else if (item.trendingNo == 4) {
        this.formData.title4 = item.title;
        this.formData.resourceType4 = item.resourceType;
      } else if (item.trendingNo == 5) {
        this.formData.title5 = item.title;
        this.formData.resourceType5 = item.resourceType;
      } else if (item.trendingNo == 6) {
        this.formData.title6 = item.title;
        this.formData.resourceType6 = item.resourceType;
      }
    });

    // this.formData.title1 = this.trendingItems[0].title;
    // this.formData.resourceType1 = this.trendingItems[0].resourceType;

    // this.formData.title2 = this.trendingItems[1].title;
    // this.formData.resourceType2 = this.trendingItems[1].resourceType;

    // this.formData.title3 = this.trendingItems[2].title;
    // this.formData.resourceType3 = this.trendingItems[2].resourceType;

    // this.formData.title4 = this.trendingItems[3].title;
    // this.formData.resourceType4 = this.trendingItems[3].resourceType;

    // this.formData.title5 = this.trendingItems[4].title;
    // this.formData.resourceType5 = this.trendingItems[4].resourceType;

    // this.formData.title6 = this.trendingItems[5].title;
    // this.formData.resourceType6 = this.trendingItems[5].resourceType;
  },
  computed: {
    items() {
      return this.$store.state.trendingObjects;
    }
  },
  methods: {
    async submitForm() {
      this.isSubmitting = true;
      const request = cloneDeep(this.formData);
      // const uploadRequest = new FormData();

      // uploadRequest.append("title1", request.title1);
      // uploadRequest.append("resourceType1", request.resourceType1);

      // uploadRequest.append("title2", request.title2);
      // uploadRequest.append("resourceType2", request.resourceType2);

      // uploadRequest.append("title3", request.title3);
      // uploadRequest.append("resourceType3", request.resourceType3);

      // uploadRequest.append("title4", request.title4);
      // uploadRequest.append("resourceType4", request.resourceType4);

      // uploadRequest.append("title5", request.title5);
      // uploadRequest.append("resourceType5", request.resourceType5);

      await this.$store.dispatch("updateTrending", request);
      this.$router.push({ name: "Dashboard" });

      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
